import React from "react";
import UseData from "../../Hooks/UseData";
import Footer from "../../Share/Footer";
import "../../Share/Style.css";
import SliderCommon from "../../Share/SliderCommon";
import AboutCard from "./AboutCard";
import HomeCard from "../../Share/HomeCard";
import SkillTags from "../../Share/SkillTags";

import PageTitle from "../../Share/PageTitle";

const AboutTwo = () => {
  const { local, experienceArray } = UseData();

  return (
    <section>
      <PageTitle title="About"></PageTitle>
      {/* End pagetitle */}

      <div className=" lg:rounded-2xl bg-white dark:bg-[#111111]">
        <div data-aos="fade">
          <div className=" pt-12 md:py-12 px-2 sm:px-5 md:px-10 lg:px-14 ">
            {/* About page title */}
            <h2 className="after-effect after:left-52">About Me</h2>
            <div className="lg:hidden">
              {/* Sidebar personal information for mobile devices */}
              <HomeCard />
              {/* <SkillTags/> */}
            </div>
            <div className="lg:grid grid-cols-12 md:gap-10 pt-4 md:pt-[30px] items-center hidden ">
              <div className="col-span-12 space-y-2.5">
                <div className="lg:mr-16">
                  <p className="text-gray-lite  dark:text-color-910 leading-7">
                    I am a software developer with hands-on experience in
                    designing, programming, debugging, and developing
                    applications and websites. Identifing clients’ needs and
                    recommending appropriate solutions, including creation of
                    pixel-perfect applications and writing clean codes is what i
                    strive for.
                  </p>
                  <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                    Experienced in all steps of the software development
                    lifecycle with knowledge of full-stack in multi-tier
                    applications. Expert knowledge of Microsoft Azure App
                    Insights and Azure DevOps. Highly experienced in building
                    APIs in REST architecture and developing SPAs, reusable
                    components, and microservices using Angular and TypeScript.
                  </p>
                </div>
                <div></div>
              </div>
            </div>
          </div>
          {/* End about descriptions */}

          <div className="  pb-12 px-2 sm:px-5 md:px-10 lg:px-14 ">
            <h3 className="text-[35px] dark:text-white font-medium pb-5">
              What I do!
            </h3>

            <div className="grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-2 ">
              {/* Experience information  */}

              {experienceArray.map((item) => (
                <AboutCard key={Math.random()} item={item} local={local} />
              ))}
            </div>
          </div>
          <div className="  pb-12 px-2 sm:px-5 md:px-10 lg:px-14 ">
            <h3 className="text-[35px] dark:text-white font-medium pb-5">
              Expertise
            </h3>
            <div className="flex gap-x-3 gap-y-3 md:gap-y-6 md:gap-x-4 flex-wrap">
              <button className="resume-btn">C#</button>
              <button className="resume-btn">.Net Core</button>
              <button className="resume-btn">Angular 2+</button>
              <button className="resume-btn">Javascript</button>
              <button className="resume-btn">Responsive Design</button>
              <button className="resume-btn">Karma</button>
              <button className="resume-btn">Jasmine</button>
              <button className="resume-btn">MicroServices</button>
              <button className="resume-btn">MVC, ASP.Net</button>
              <button className="resume-btn">Rest API</button>
              <button className="resume-btn">Linq</button>
              <button className="resume-btn">Testing Framework</button>
              <button className="resume-btn">Azure </button>
              <button className="resume-btn">Devops</button>
              <button className="resume-btn">Entity Framework</button>
              <button className="resume-btn">PostgresQL</button>
              <button className="resume-btn">MondoDB</button>
              <button className="resume-btn">TSQl, P/L SQL </button>
              <button className="resume-btn">Nsawg /OpenAPI</button>
              <button className="resume-btn">API Gateway </button>
              <button className="resume-btn">CI/CD</button>
            </div>
          </div>

          {/* service provide end */}

          <div className="px-2 sm:px-5 md:px-10 lg:px-14 ">
            {/* Slick Slider call here  */}
            <SliderCommon />
          </div>

          {/* Common Footer call here */}
          <Footer bg={"#FFFF"} />
        </div>
      </div>
    </section>
  );
};

export default AboutTwo;
